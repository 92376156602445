import Vue from "vue";
import { GridPlugin, Toolbar, Page, Filter, Sort, CommandColumn } from "@syncfusion/ej2-vue-grids";
import { DialogUtility } from "@syncfusion/ej2-popups";
import { CustomUrlAdaptor } from "@/syncfusion/CustomUrlAdaptor";
import { DataManager } from "@syncfusion/ej2-data";
import SpinnerMixin from "@/mixins/spinner";
import titleMixin from "@/mixins/title";
import gridMixin from "@/mixins/syncfusion/grid";
import LocaleMixin from "@/mixins/locale";
import { formatDate, formatDateTime, formatTime } from "@/helpers/dateTimeHelper";
import AppointmentsPaymentDialog from "@/components/Templates/Customer/AppointmentsPaymentDialog.vue";
import SearchToolbar from "@/components/Templates/Payments/SearchToolbar.vue";
import { addDays, getToday } from "@/helpers/dateTimeHelper";
import { mapGetters } from "vuex";
Vue.use(GridPlugin);
export default Vue.extend({
    mixins: [gridMixin, titleMixin, LocaleMixin, SpinnerMixin],
    components: {
        "appointments-payment-dialog": AppointmentsPaymentDialog,
        "search-toolbar": SearchToolbar
    },
    data() {
        return {
            customersDataSource: new DataManager({
                url: process.env.VUE_APP_API_BASE_URL + "customers/grid-data",
                //insertUrl: process.env.VUE_APP_API_BASE_URL + "customers/grid-create",
                //updateUrl: process.env.VUE_APP_API_BASE_URL + "customers/grid-edit",
                //removeUrl: process.env.VUE_APP_API_BASE_URL + "customers/grid-delete",
                adaptor: new CustomUrlAdaptor()
            }),
            title: this.$t('payments.menu'),
            data: new DataManager({
                url: process.env.VUE_APP_API_BASE_URL +
                    `appointments/payments/grid-data`,
                //insertUrl: process.env.VUE_APP_API_BASE_URL + "customers/grid-create",
                //updateUrl: process.env.VUE_APP_API_BASE_URL + "customers/grid-edit",
                //removeUrl: process.env.VUE_APP_API_BASE_URL + "customers/grid-delete",
                adaptor: new CustomUrlAdaptor()
            }),
            toolbarOptions: [
                {
                    text: this.$t("payments.update.submit"),
                    prefixIcon: "e-payment",
                    id: "SetPayment"
                }
            ],
            commands: [
                {
                    type: "SetPayment",
                    title: this.$t("payments.update.submit"),
                    buttonOption: { cssClass: "e-flat", iconCss: "e-payment e-icons" }
                },
                {
                    type: "RemovePayment",
                    title: this.$t("payments.delete.submit"),
                    buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" }
                }
            ],
            sortOptions: {
                columns: [
                    { field: "StartDateTime", direction: "Ascending" },
                ]
            },
            filterOptions: { type: "Menu",
                columns: [
                    { field: 'StartDateTime', matchCase: false, operator: 'greaterThanOrEqual', predicate: 'and', value: getToday() },
                    { field: 'EndDateTime', matchCase: false, operator: 'lessThanOrEqual', predicate: 'and', value: addDays(getToday(), 1) },
                    { field: 'CancelType', matchCase: false, operator: 'equal', predicate: 'and', value: [0, 2] },
                    { field: 'IsPaid', matchCase: false, operator: 'equal', predicate: 'and', value: false },
                ],
            },
            pageSettings: { pageSize: 10 },
            selectedAppointments: []
        };
    },
    provide: {
        grid: [Toolbar, Page, Filter, Sort, CommandColumn]
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices"
        })
    },
    methods: {
        search(args) {
            const grid = this.$refs["appointment-grid"];
            const gridInstance = grid.ej2Instances;
            const filters = [];
            // Add grid filters
            for (const column of gridInstance.filterSettings.columns) {
                if (!["OfficeId", "CustomerId", "ServiceId", "StartDateTime", "EndDateTime", "CancelType", "IsPaid"].includes(column.field))
                    filters.push(column);
            }
            // Add advanced filters
            filters.push({ field: 'StartDateTime', matchCase: false, operator: 'greaterThanOrEqual', predicate: 'and', value: args.startDate });
            filters.push({ field: 'EndDateTime', matchCase: false, operator: 'lessThanOrEqual', predicate: 'and', value: addDays(args.endDate, 1) });
            if (args.officeId != null)
                filters.push({ field: 'OfficeId', matchCase: false, operator: 'equal', predicate: 'and', value: args.officeId });
            if (args.customerIds != null && args.customerIds.length > 0)
                filters.push({ field: 'CustomerId', matchCase: false, operator: 'equal', predicate: 'and', value: args.customerIds });
            if (args.serviceId != null)
                filters.push({ field: 'ServiceId', matchCase: false, operator: 'equal', predicate: 'and', value: args.serviceId });
            if (args.cancelTypes && args.cancelTypes.length > 0)
                filters.push({ field: 'CancelType', matchCase: false, operator: 'equal', predicate: 'and', value: args.cancelTypes });
            if (args.paid != -1)
                filters.push({ field: 'IsPaid', matchCase: false, operator: 'equal', predicate: 'and', value: args.paid === 1 });
            // Set filters and refresh grid
            gridInstance.filterSettings.columns = filters;
            grid.refresh();
        },
        toolbarClick(args) {
            if (args.item.id == "SetPayment") {
                const grid = this.$refs["appointment-grid"];
                this.selectedAppointments = grid.getSelectedRecords();
                if (this.selectedAppointments.length > 0) {
                    this.$refs.appointmentsPaymentDialog.show();
                }
                return;
            }
        },
        async commandClick(args) {
            if (args.commandColumn.type === "SetPayment") {
                this.selectedAppointments = [args.rowData];
                this.$refs.appointmentsPaymentDialog.show();
                return;
            }
            if (args.commandColumn.type === "RemovePayment") {
                const dialog = DialogUtility.confirm({
                    title: "",
                    content: this.$t("payments.delete.confirm", {
                        date: this.formatDateTime(args.rowData.StartDateTime)
                    }),
                    showCloseIcon: false,
                    closeOnEscape: true,
                    okButton: {
                        text: "Ok",
                        click: async () => {
                            await this.$store.dispatch("customer/deleteAppointmentPayment", {
                                id: args.rowData.CustomerId,
                                appointmentId: args.rowData.AppointmentId
                            });
                            dialog.close();
                            this.$toast.showSuccessToast(this.$t("payments.delete.toastSuccessTitle"), this.$t("payments.delete.toastSuccessContent", {
                                name: args.rowData.CustomerFullName
                            }));
                            this.refreshGrid();
                        }
                    },
                    cancelButton: {
                        text: "Annulla",
                        click: () => {
                            dialog.close();
                            this.refreshGrid();
                        }
                    },
                    position: {
                        X: "center",
                        Y: "center"
                    }
                });
                return;
            }
        },
        onAppointmentsPaymentDialogClose() {
            this.refreshGrid();
        },
        formatDateTime(value) {
            return formatDateTime(this.appLocale, value);
        },
        refreshGrid() {
            const grid = this.$refs["appointment-grid"];
            grid.refresh();
        },
        dateTimeColumn(field, data, column) {
            return `${formatDate(this.appLocale, data.StartDateTime)} ${formatTime(this.appLocale, data.StartDateTime)} - ${formatTime(this.appLocale, data.EndDateTime)}`;
        },
        cancelTypeGridValueMapper(field, data, column) {
            switch (data[field]) {
                case 0:
                    return this.$t("calendar.cancelType.notCanceled");
                case 1:
                    return this.$t("calendar.cancelType.canceledWithoutCharge");
                case 2:
                    return this.$t("calendar.cancelType.canceledWithCharge");
                default:
                    return "Sconosciuto";
            }
        },
    }
});
