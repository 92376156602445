import Vue from "vue";
import { DialogPlugin } from "@syncfusion/ej2-vue-popups";
import ContentTemplate from "./ChangePasswordModalContent.vue";
Vue.use(DialogPlugin);
export default Vue.extend({
    data() {
        return {
            dialogContentTemplate: function () {
                return { template: ContentTemplate };
            }
        };
    },
    methods: {
        open() {
            this.$refs["changePasswordDialog"].show();
        },
        close() {
            this.$refs["changePasswordDialog"].hide();
        }
    },
    mounted() {
        this.$root.$on("openChangePasswordModal", this.open);
        this.$root.$on("changePasswordSuccess", this.close);
        this.$root.$on("changePasswordCancelled", this.close);
    },
    beforeDestroy() {
        this.$root.$off("openChangePasswordModal", this.open);
        this.$root.$off("changePasswordSuccess", this.close);
        this.$root.$off("changePasswordCancelled", this.close);
    }
});
