import Vue from "vue";
import SpinnerMixin from "@/mixins/spinner";
import { getToday } from "@/helpers/dateTimeHelper";
import { mapGetters } from "vuex";
export default Vue.extend({
    mixins: [SpinnerMixin],
    data() {
        return {
            officesData: [],
            customersData: [],
            services: [],
            cancelTypeData: [
                { name: this.$t("calendar.cancelType.notCanceled"), id: 0 },
                { name: this.$t("calendar.cancelType.canceledWithoutCharge"), id: 1 },
                { name: this.$t("calendar.cancelType.canceledWithCharge"), id: 2 }
            ],
            startDate: getToday(),
            endDate: getToday(),
            officeId: null,
            customerIds: [],
            serviceId: null,
            cancelTypes: [0, 2],
            paid: 0,
            validationOptions: {
                rules: {
                    startDate: {
                        required: true
                    },
                    endDate: {
                        required: true
                    }
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices"
        }),
        officeServices() {
            if (this.officeId == null)
                return this.services;
            return this.services.filter(service => service.offices?.some(serviceOffice => serviceOffice.officeId === this.officeId));
        }
    },
    methods: {
        search() {
            this.$emit("search", {
                startDate: this.startDate,
                endDate: this.endDate,
                officeId: this.officeId,
                customerIds: this.customerIds,
                serviceId: this.serviceId,
                cancelTypes: this.cancelTypes,
                paid: this.paid
            });
        }
    },
    async mounted() {
        //this.showSpinner();
        try {
            this.officesData = await this.$store.dispatch("office/getAll");
            this.customersData = await this.$store.dispatch("customer/getAll", {
                load: true
            });
            this.services = await this.$store.dispatch("service/getAll", {
                load: false
            });
            this.customersData = this.customersData.filter(customer => customer.deletedAt == undefined);
            this.customersData.forEach((customer) => (customer.fullName = `${customer.name} ${customer.surname}`));
            this.customersData.unshift({
                id: null,
                fullName: this.$t('payments.allCustomers')
            });
            this.officesData.unshift({
                id: null,
                name: this.$t("offices.all")
            });
            this.services = this.services.filter(service => service.deletedAt == undefined);
            this.services.unshift({
                id: null,
                name: this.$t('services.all')
            });
            //this.hideSpinner();
        }
        catch (errors) {
            //this.hideSpinner();
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
        }
    }
});
