import Vue from "vue";
import store from "@/store";
import AppNavigationItem from "./AppNavigationItem.vue";
export default Vue.extend({
    components: {
        AppNavigationItem
    },
    props: {
        menuItem: {
            type: Object,
            required: true
        }
    },
    computed: {
        isVisible() {
            if (this.menuItem.children !== undefined) {
                const role = store.getters["account/getRole"];
                for (let iItem = 0; iItem < this.menuItem.children.length; iItem++) {
                    const item = this.menuItem.children[iItem];
                    if (this.menuItem.meta === undefined ||
                        this.menuItem.meta.authorize === undefined ||
                        this.menuItem.meta.authorize.includes(role))
                        return true;
                }
            }
            return false;
        },
        isActive() {
            if (this.menuItem.children !== undefined) {
                for (let iItem = 0; iItem < this.menuItem.children.length; iItem++) {
                    const item = this.menuItem.children[iItem];
                    if (this.$route.path.startsWith(item.path)) {
                        return true;
                    }
                }
            }
            return false;
        }
    }
});
